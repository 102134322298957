import React from "react";
import "./Projects.css";
import { motion } from "framer-motion";
import Data from "./ProjectData";
import ProjectCard from "./ProjectCard";

function Projects() {
  return (
    <div className="project-section" id="projects">
      <motion.h1
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="header"
      >
        My Projects
      </motion.h1>
      <motion.p
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: 50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        From creating e-commerce platforms that significantly bolster sales
        figures to crafting dynamic web applications that captivate and retain
        users, my passion lies in consistently delivering pixel-perfect
        solutions punctually, without compromise.
      </motion.p>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.5, delayChildren: 0.1, staggerChildren: 0.3 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
        className="project-container"
      >
        {Data.map((data) => {
          return (
            <motion.li
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
              }}
            >
              <ProjectCard
                img={data.img}
                title={data.title}
                about={data.about}
                framework={data.framework}
                link={data.link}
              />
            </motion.li>
          );
        })}
      </motion.div>
    </div>
  );
}

export default Projects;
