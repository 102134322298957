import React from "react";
import "./ScrollUp.css";
import logo from "./../../Assests/logo.png";

function ScrollUp() {
  const scrolling = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main" onClick={scrolling}>
      <img src={logo} alt="logo" height="28px" width="28px" />
    </div>
  );
}

export default ScrollUp;
