import React from "react";
import "./Labels.css";

function Labels(props) {
  return (
    <div className="label-content">
      <img src={props.icon} alt={props.alt} className="contact-icon" />
      <span>{props.name}</span>
    </div>
  );
}

export default Labels;
