import keeper from "../../Assests/projects/keeper.png";
import codeEdit from "../../Assests/projects/code edit.png";
import findMyPG from "../../Assests/projects/findmypg.png";
import travelDiaries from "../../Assests/projects/traveldiaries.png";
import intellisense from "../../Assests/projects/intellisense.png";
import beehive from "../../Assests/projects/beehive.png";

const Data = [
  {
    id: 1,
    img: keeper,
    title: "Keeper",
    about:
      "A web-based application that provides a convenient way for users to create, organize and access their notes online.",
    framework: "React.js, html, CSS, bootstrap",
    link: "https://github.com/Aditya-Muni/Notes",
  },
  {
    id: 2,
    img: codeEdit,
    title: "CodeEdit",
    about:
      " A code editor that helps users to write down code in html, CSS or javaScript and see the output in realtime",
    framework: "React.js, html, CSS, Material UI,CodeMirror",
    link: "https://github.com/Aditya-Muni/Code-Edit",
  },
  {
    id: 3,
    img: findMyPG,
    title: "Find my PG",
    about:
      "A platform for students to find a new home in the new city, they can live as tenants or paying guests",
    framework: "React.js, Material UI, Tailwind, expressJS, node.js, mongoDB",
    link: "https://github.com/Aditya-Muni/FindMyPG",
  },
  {
    id: 4,
    img: beehive,
    title: "BeeHive",
    about:
      "An e-commerce market place allowing users to shop as well as sell digital assets with ease.",
    framework:
      "Next.js, Typescript, Tailwind, Stripe, MongoDB, AWS S3, Payload CMS, Express, Shadcn, Resend.",
    link: "https://beehive-hmb1.onrender.com/",
  },
  {
    id: 5,
    img: intellisense,
    title: "Intellisense",
    about:
      "A full stack web application enabling users to upload PDFs ask AI questions from their PDF",
    framework:
      "NextJS, Typescript, Shadcn, Tailwind, trpc, Prisma, Pinecone, Stripe, Kinde.",
    link: "https://intellisense-omega.vercel.app",
  },
  {
    id: 6,
    img: travelDiaries,
    title: "Coming Soon",
    about:
      "A web application that allows users to know about their trip through other travelers experiences",
    framework: "will be updated",
  },
];

export default Data;
