import React, { useState } from "react";
import "./NavBar.css";
import logo from "../../Assests/logo.png";
import { Link } from "react-scroll";

function NavBar() {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 50) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <div className={navbar ? "nav-container active" : "nav-container"}>
      <img src={logo} alt="logo" height="48px" width="48px" className="logo" />
      <div className="section-container">
        <Link
          activeClass="active"
          to="hero"
          spy={true}
          smooth={true}
          offset={-250}
          duration={500}
          className="section-items"
        >
          Home
        </Link>
        <Link
          activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          className="section-items"
        >
          About me
        </Link>
        <Link
          activeClass="active"
          to="skills"
          spy={true}
          smooth={true}
          offset={-160}
          duration={500}
          className="section-items"
        >
          Skills
        </Link>
        <Link
          activeClass="active"
          to="projects"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
          className="section-items"
        >
          Projects
        </Link>
        {/* <span className="section-items">Home</span>
        <span className="section-items">About me</span>
        <span className="section-items">Skills</span>
        <span className="section-items">Projects</span> */}
      </div>
      <Link
        activeClass="active"
        to="contact"
        spy={true}
        smooth={true}
        offset={-90}
        duration={500}
        className="contact-btn"
      >
        Contact
      </Link>
    </div>
  );
}

export default NavBar;
