import "./App.css";
import NavBar from "./Components/NavBar/NavBar";
import Hero from "./Components/Hero/Hero";
import About from "./Components/About/About.js";
import Skills from "./Components/Skills/Skills";
import Projects from "./Components/Projects/Projects";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import Spots from "./Components/Spots/Spots";
import ScrollUp from "./Components/ScrollUp/ScrollUp";
import { useRef } from "react";
import { motion, useScroll } from "framer-motion";
// import logo from "./Assests/logo.png";

function App() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 0", "1 1"],
  });
  return (
    <>
      <div className="scrollup-container">
        <ScrollUp />
        <svg id="progress" width="100" height="100" viewBox="0 0 100 100">
          <motion.circle
            cx="50"
            cy="50"
            r="32"
            pathLength="1"
            className="indicator"
            style={{ pathLength: scrollYProgress }}
          />
        </svg>
      </div>
      <div className="body" ref={ref}>
        <NavBar />
        <Spots />
        <div className="App">
          <Hero />
          <About />
          <Skills />
          <Projects />
          <Contact />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
