import React from "react";
import Tiles from "./Tiles";
import "./Skills.css";
import Data from "./SkillsData";
import { motion } from "framer-motion";

function Skills() {
  return (
    <div className="skill-section" id="skills">
      <motion.h1
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="header"
      >
        My Skills
      </motion.h1>
      <motion.p
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: 50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        Over the years, I've dedicated myself to refining my experience in a
        multitude of technologies and frameworks, a selection of which I'd like
        to highlight here
      </motion.p>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.1, delayChildren: 0.2, staggerChildren: 0.1 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
        className="skills-tiles"
      >
        {Data.map((data) => {
          return (
            <motion.li
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
              }}
            >
              <Tiles skill={data.name} icon={data.logo} />
            </motion.li>
          );
        })}
      </motion.div>
    </div>
  );
}

export default Skills;
