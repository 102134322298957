import React from "react";
import { motion } from "framer-motion";
import "./Spots.css";
import type1 from "./../../Assests/spot1.png";
import type2 from "./../../Assests/spot2.png";
import type3 from "./../../Assests/spot3.png";
import circle1 from "./../../Assests/BlueCircle.png";
import circle2 from "./../../Assests/FadedCircle.png";
import cross1 from "./../../Assests/cross1.png";
import cross2 from "./../../Assests/cross2.png";

const circleVariants = {
  visible: {
    scale: [0.8, 1.3, 0.8],
    transition: {
      duration: 3,
      repeat: Infinity,
    },
  },
};

function Spots() {
  return (
    <div className="bgLayer-container">
      <motion.div className="shape-div">
        {/* BlueCircle */}
        <motion.img
          animate={{ translateY: [-10, 10, -10] }}
          transition={{ ease: "linear", duration: 3, repeat: Infinity }}
          src={circle1}
          alt="."
          className="bgLayer top-right "
        />
        <motion.img
          animate={{
            scale: [1.2, 0.9, 1.2, 0.9, 1.2],
            translateX: [10, -10, 10],
            translateY: [-10, 10, -10],
          }}
          transition={{ ease: "linear", duration: 5, repeat: Infinity }}
          src={circle1}
          alt="."
          className="bgLayer bottom-right"
        />
        <motion.img
          animate={{
            translateY: [15, -15, 15],
          }}
          transition={{ ease: "linear", duration: 3, repeat: Infinity }}
          src={circle1}
          alt="."
          className="bgLayer bottom-left "
        />
        <motion.img
          animate={{
            scale: [1.2, 0.9, 1.2, 0.9, 1.2],
            translateX: [-10, 10, -10],
            translateY: [10, -10, 10],
          }}
          transition={{ ease: "linear", duration: 5, repeat: Infinity }}
          src={circle1}
          alt="."
          className="bgLayer top-left "
        />

        {/* cross2 up down  */}
        <motion.img
          animate={{ translateY: [-35, 35, -35], rotateZ: 720 }}
          transition={{ ease: "linear", duration: 5, repeat: Infinity }}
          src={cross2}
          alt="."
          className="bgLayer left"
        />
        <motion.img
          animate={{ translateY: [-30, 30, -30], rotateZ: 480 }}
          transition={{ ease: "linear", duration: 4, repeat: Infinity }}
          src={cross2}
          alt="."
          className="bgLayer right"
        />
        {/* cross1  moving */}
        <motion.img
          animate={{
            translateY: [45, 0, -45, 0, 45],
            translateX: [0, -35, 0, 35, 0],
            rotateZ: 720,
          }}
          transition={{ ease: "linear", duration: 5, repeat: Infinity }}
          src={cross1}
          alt="."
          className="bgLayer moving-left"
        />
        <motion.img
          animate={{
            translateY: [20, 0, -20, 0, 20],
            translateX: [0, -20, 0, 20, 0],
            rotateZ: 720,
          }}
          transition={{ ease: "linear", duration: 3.5, repeat: Infinity }}
          src={cross1}
          alt="."
          className="bgLayer moving-right"
        />
        {/* FadedCircle */}
        <motion.img
          variants={circleVariants}
          animate="visible"
          src={circle2}
          alt="."
          className="bgLayer top"
        />
        <motion.img
          variants={circleVariants}
          animate="visible"
          src={circle2}
          alt="."
          className="bgLayer bottom"
        />
      </motion.div>
      {/* background spots */}
      <img src={type1} alt="." className="bgLayer spot1" />
      <img src={type2} alt="." className="bgLayer spot2" />
      <img src={type3} alt="." className="bgLayer spot3" />
      <img src={type3} alt="." className="bgLayer spot4" />
      <img src={type2} alt="." className="bgLayer spot5" />
      <img src={type3} alt="." className="bgLayer spot6" />
      <img src={type3} alt="." className="bgLayer spot7" />
      <img src={type2} alt="." className="bgLayer spot8" />
    </div>
  );
}

export default Spots;
