import node from "../../Assests/nodejs.png";
import sql from "../../Assests/icons8-sql-52 (1).png";
import mongodb from "../../Assests/mongoDB.png";
import nextjs from "../../Assests/nextjs.png";
import typescript from "../../Assests/typescript (2).png";
import express from "../../Assests/express.png";
import reactjs from "../../Assests/react.png";
import tailwind from "../../Assests/tailwind.png";
import github from "../../Assests/git&github.png";
import css from "../../Assests/css.png";
import framer from "../../Assests/framer.png";
import html from "../../Assests/html.png";
import figma from "../../Assests/figma (2).png";
import javascript from "../../Assests/javascript.png";
import docker from "../../Assests/docker.png";

const SkillsData = [
  {
    name: "SQL",
    logo: sql,
  },
  {
    name: "MongoDB",
    logo: mongodb,
  },
  {
    name: "Next.js",
    logo: nextjs,
  },
  {
    name: "TypeScript",
    logo: typescript,
  },
  {
    name: "Node.js",
    logo: node,
  },
  {
    name: "express",
    logo: express,
  },
  {
    name: "docker",
    logo: docker,
  },
  {
    name: "React.js",
    logo: reactjs,
  },
  {
    name: "Tailwind",
    logo: tailwind,
  },
  {
    name: "Git&GitHub",
    logo: github,
  },
  {
    name: "CSS",
    logo: css,
  },
  {
    name: "Framer Motion",
    logo: framer,
  },
  {
    name: "HTML",
    logo: html,
  },
  {
    name: "Figma",
    logo: figma,
  },
  {
    name: "JavaScript",
    logo: javascript,
  },
];

export default SkillsData;
