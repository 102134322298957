import React from "react";
import "./ProjectCard.css";

function ProjectCard(props) {
  return (
    <div className="box ">
      {" "}
      <a target="_blank" rel="noreferrer" href={props.link}>
        <img className="box-img" src={props.img} alt="" />
        <div className="card-content">
          <h2 className="card-title">{props.title}</h2>
          <p>{props.about}</p>
          <h4 className="phone-view">Frameworks & libraries</h4>
          <p className="phone-view">{props.framework}</p>
          {/* github */}
        </div>
      </a>
    </div>
  );
}

export default ProjectCard;
