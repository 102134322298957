import React, { useRef, useState } from "react";
import "./Contact.css";
import Labels from "./Labels";
import location from "./../../Assests/location-1.png";
import mail from "./../../Assests/mail-1.png";
import phone from "./../../Assests/phone-1.png";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";

function Contact() {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  /* ============== emailJS code ============== */
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rdee06d",
        "template_8nchck8",
        form.current,
        "DA6CSLoSP_RQHeO8t"
      )
      .then(
        (result) => {
          /* ============== react toast ============== */
          toast.info(" Message sent successfully", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          console.log("success");
          setName("");
          setEmail("");
          setMessage("");
        },
        (error) => {
          console.log("fail");
        }
      );
  };

  return (
    <div className="Contact-section" id="contact">
      <motion.h1
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="header"
      >
        Let's Connect
      </motion.h1>
      <motion.p
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: 50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        Whether you have a project in mind, want to discuss a tech trend, or
        just want to say hi, feel free to reach out. Let's build something
        incredible together!
      </motion.p>
      <div className="labels-container">
        <Labels
          icon={phone}
          name="+91 9131425389"
          alt="phone"
          className="labels"
        />
        <Labels
          icon={mail}
          name="adityamuni1206@gmail.com"
          alt="mail"
          className="labels"
        />
        <Labels
          icon={location}
          name="Chhattisgarh, India"
          alt="location"
          className="labels"
        />
      </div>

      <motion.form
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
        ref={form}
        onSubmit={sendEmail}
      >
        <div className="form">
          <div className="upper-div">
            <textarea
              type="text"
              placeholder="Your Name*"
              name="from_name"
              rows={1}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="upper-input"
            />
            <textarea
              type="email"
              placeholder="Your Email*"
              name="from_email"
              rows={1}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="upper-input"
            />
          </div>
          <textarea
            name="message"
            placeholder="Message*"
            rows={7}
            className="lower-input"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <input type="submit" value="Send Message" className="message-btn" />
      </motion.form>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default Contact;
