import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <div className="footer-section">
      <div>
        <a
          className="social-link"
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/aditya_muni_"
        >
          <FontAwesomeIcon icon={faSquareXTwitter} className="social-icon" />
          Twitter
        </a>
        <a
          className="social-link"
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/adityamuni"
        >
          <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
          Linkedin
        </a>
        <a
          className="social-link"
          target="_blank"
          rel="noreferrer"
          href="https://github.com/Aditya-Muni"
        >
          <FontAwesomeIcon icon={faGithub} className="social-icon" />
          Github
        </a>
      </div>
      Created by: <span>Aditya Muni</span>
    </div>
  );
}

export default Footer;
