import React from "react";
import "./About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import resume from "../../Assests/Resume_Aditya.pdf";
import { motion } from "framer-motion";

function About() {
  return (
    <div className="about-section" id="about">
      <motion.h1
        className="header"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.8 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        About Me
      </motion.h1>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: 50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="about-para-container"
      >
        <p className="about-para">
          As a final year Computer Science student with a profound passion for
          web development, my journey in this field has been nothing short of
          exhilarating. It all began as a quest to blend creativity with
          problem-solving, and from that moment, I have never looked back. With
          every project, I strive to weave together my development skills to
          craft robust and highly responsive applications that not only meet but
          exceed the unique needs and objectives of each endeavor.
          <br />
        </p>
        <p className="about-para">
          My experience lies in a diverse range of stacks, with a particular
          fondness for the MERN. I thrive on turning concepts into tangible,
          user-friendly digital experiences. Whether it's experimenting with
          cutting-edge frameworks or fine-tuning the existing ones, I
          wholeheartedly embrace the challenge of pushing boundaries. In this
          ever-evolving field, I am committed to staying updated with the latest
          trends and technologies, continuously expanding my knowledge Beyond my
          academic pursuits, personal projects, collaborations, and open-source
          contributions have been pivotal in my growth as a web developer. My
          journey in web development is a testament to my unwavering dedication
          and enthusiasm for the craft.
        </p>
        <p className="about-para-mobile">
          As a final year Computer Science student with a profound passion for
          web development, My experience lies in a diverse range of stacks, with
          a particular fondness for the MERN.With every project, I strive to
          weave together my development skills to craft robust and highly
          responsive applications that not only meet but exceed the unique needs
          and objectives of each endeavor. In this ever-evolving field, I am
          committed to staying updated with the latest trends and technologies,
          continuously expanding my knowledge Beyond my academic pursuits,
          personal projects, collaborations, and open-source contributions have
          been pivotal in my growth as a web developer.
        </p>
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <div className="hireMe">Want to Hire me ? Here is my resume.</div>
        <a href={resume} download="Resume" className="resume-btn">
          <FontAwesomeIcon icon={faPaperclip} className="paper-clip" />
          <div>Resume</div>
        </a>
      </motion.div>
    </div>
  );
}

export default About;
