import React from "react";
import "./Tiles.css";

function Tiles(props) {
  return (
    <div className="skill-btn">
      {
        <img
          src={props.icon}
          alt="clip"
          height="32px"
          width="auto"
          className="skill-icon"
        ></img>
      }
      <p>{props.skill}</p>
    </div>
  );
}

export default Tiles;
